<template>
  <div>
    <v-card
      id="define-map"
      flat
      elevation="8"
      height="75vh"
      width="80vw"
    >
      <div
        id="map"
        class="map"
      />
    </v-card>

    <div
      v-if="Object.keys(ticketing.searchFilters.geographicalReferences).length"
      class="legend"
    >
      <div class="legend-item">
        <v-icon
          class="legend-color"
          color="green"
        >
          mdi-border-radius
        </v-icon>
        <span>1km</span>
      </div>
      <div class="legend-item">
        <v-icon
          class="legend-color"
          color="blue"
        >
          mdi-border-radius
        </v-icon>
        <span>3km</span>
      </div>
      <div class="legend-item">
        <v-icon
          class="legend-color"
          color="red"
        >
          mdi-border-radius
        </v-icon>
        <span>5km</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { pointerMove } from 'ol/events/condition';
import { Vector as VectorLayer } from 'ol/layer';
import { Cluster, Vector as VectorSource } from 'ol/source.js';
import Feature from 'ol/Feature.js';
import { Circle as CircleLayer, Point } from 'ol/geom.js';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';
import * as olExtent from 'ol/extent';

export default {
  props: {
    requests: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      overlay: null,
      hoverInteraction: null
    };
  },
  computed: {
    ...mapState(['layers', 'ticketing'])
  },
  async mounted() {
    await this.initiateMap();
  },
  methods: {
    ...mapActions(['startMap']),
    async initiateMap() {
      this.startMap('EPSG:3857');

      if (Object.keys(this.ticketing.searchFilters.geographicalReferences).length) {
        this.highlightArea();
      }

      this.addRequestLayer();
      this.clickConfig();
      this.overlayConfig();
    },
    addRequestLayer() {
      const combinedSource = new VectorSource();

      this.requests.forEach(req => {
        const features = req.locations.map(e => {
          const { address, meteo_type, latitude, longitude, start_date, end_date } = e;

          const feature = new Feature({
            geometry: new Point(fromLonLat([longitude, latitude], 'EPSG:3857'))
          });
          feature.setProperties({
            request_id: req.request_id,
            req_date: req.request_date,
            req_num: req.request_count,
            req_type: req.request_type,
            address: address,
            document_type: req.document_type.split(',')[0],
            meteo_type: meteo_type.split(',')[0],
            start_date: start_date,
            end_date: end_date,
            latitude: latitude,
            longitude: longitude,
            req_state: req.request_state
          });

          return feature;
        });
        combinedSource.addFeatures(features);
      });

      const clusterSource = new Cluster({
        distance: 20,
        source: combinedSource
      });

      const multipleFeatureStyle = (size) => {
        return new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({ color: '#0da344' }),
            stroke: new Stroke({ color: 'black' })
          }),
          text: new Text({
            font: '12px Monospace',
            textAlign: 'center',
            textBaseline: 'middle',
            text: size.toString(),
            fill: new Fill({ color: '#fff' })
          })
        });
      };

      const clusterLayer = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
          const features = feature.get('features');
          // const same_request_id = features.every((feat, index, array) => {
          //   return index === 0 || feat.get('request_id') === array[index - 1].get('request_id');
          // });

          return multipleFeatureStyle(features.length);
        }
      });

      clusterLayer.set('layer_type', 'TICKET');
      clusterLayer.set('layer_name', 'TICKET LAYER');

      this.layers.map.addLayer(clusterLayer);
    },
    clickConfig() {
      this.layers.map.on('click', (event) => {
        this.layers.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          this.$emit('show-requests', feature);

          return true;
        });
      });
    },
    overlayConfig() {
      const POPUP_LIMIT = 8;

      this.overlay = new Overlay({
        positioning: 'center-left',
        offset: [10, 0],
        element: document.createElement('div')
      });
      this.layers.map.addOverlay(this.overlay);

      this.hoverInteraction = new Select({
        layers: function (layer) {
          return layer.values_.layer_type === 'TICKET';
        },
        condition: pointerMove,
        style: [new Style({
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: '#C1E3C2' }),
            stroke: new Stroke({ color: '#0da344', width: 2 })
          })
        })]
      });

      this.hoverInteraction.on('select', (event) => {
        const selectedFeatures = event.selected.length ? event.selected[0].values_.features : [];

        if (selectedFeatures.length > 0) {
          this.overlay.setPosition(event.selected[0].getGeometry().flatCoordinates);
          this.overlay.getElement().innerHTML = `
            <style>
              .overlay-style {
                color: white;
                font: 11px Monospace;
                padding: 5px 10px;
                background-color: rgba(0, 0, 0, 1);
                border: 1px solid green;
                border-radius: 5px;
                z-index: 100;
              }
            </style>`;

          const dataMap = {};
          selectedFeatures.forEach((feature) => {
            const req_id = feature.get('request_id');

            if (!dataMap[req_id]) {
              dataMap[req_id] = {
                req_date: feature.get('req_date'),
                req_num: feature.get('req_num'),
                req_type: feature.get('req_type'),
                document_type: feature.get('document_type'),
                meteo_type: feature.get('meteo_type'),
                latitude: feature.get('latitude'),
                longitude: feature.get('longitude'),
                req_state: feature.get('req_state'),
                locations: `${feature.get('start_date')} / ${feature.get('end_date')} - ${feature.get('address')}</br>`
              };
            } else {
              dataMap[req_id].locations += `${feature.get('start_date')} / ${feature.get('end_date')} - ${feature.get('address')}</br>`;
              dataMap[req_id].meteo_type += dataMap[req_id].meteo_type.includes(feature.get('meteo_type')) ? '' : ` / ${feature.get('meteo_type')}`;
            }
          });

          if (Object.keys(dataMap).length <= POPUP_LIMIT) {
            let popupData = '';
            Object.keys(dataMap).forEach((req_id, index) => {
              popupData += `
                ${index > 0 ? '---</br>' : ''}
                <b><i>
                  ${dataMap[req_id].req_num} - ${dataMap[req_id].req_type} - ${dataMap[req_id].req_date} - ${dataMap[req_id].req_state}
                </i></b></br>
                ${dataMap[req_id].document_type}</br>
                ${dataMap[req_id].meteo_type}</br>
                ${dataMap[req_id].locations}`;
            });

            this.overlay.getElement().innerHTML += `<div class="overlay-style">${popupData}</div>`;
          } else {
            this.overlay.getElement().innerHTML += '<div class="overlay-style">Click to show details...</div>';
          }

          this.overlay.getElement().style.display = 'block';
        } else {
          this.overlay.getElement().style.display = 'none';
        }
      });

      this.layers.map.addInteraction(this.hoverInteraction);
    },
    highlightArea() {
      const lat = this.ticketing.searchFilters.latitude;
      const lon = this.ticketing.searchFilters.longitude;
      const radius = parseFloat(this.ticketing.searchFilters.radius) * 111320;

      const highlightedZone = new Feature({
        geometry: new CircleLayer(fromLonLat([lon, lat], 'EPSG:3857'), radius)
      });
      const _1km = new Feature({
        geometry: new CircleLayer(fromLonLat([lon, lat], 'EPSG:3857'), 1000)
      });
      const _3km = new Feature({
        geometry: new CircleLayer(fromLonLat([lon, lat], 'EPSG:3857'), 3000)
      });
      const _5km = new Feature({
        geometry: new CircleLayer(fromLonLat([lon, lat], 'EPSG:3857'), 5000)
      });

      highlightedZone.setProperties({ name: 'highlightedZone' });
      _1km.setProperties({ name: '_1km' });
      _3km.setProperties({ name: '_3km' });
      _5km.setProperties({ name: '_5km' });

      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [highlightedZone]
        }),
        style: [new Style({
          stroke: new Stroke({ color: 'transparent', width: 2 }),
          fill: new Fill({ color: 'rgba(13,163,68,0.2)' })
        })]
      });

      const distanceLayer = new VectorLayer({
        source: new VectorSource({
          features: [_1km, _3km, _5km]
        }),
        style: function(feature) {
          const colors = {
            _1km: 'rgba(13,163,68,1)',
            _3km: 'rgba(0,0,255,1)',
            _5km: 'rgba(255,0,0,1)'
          };

          return new Style({
            stroke: new Stroke({ color: colors[feature.get('name')], width: 1 }),
            fill: new Fill({ color: 'transparent' })
          });
        }
      });

      // vectorLayer.set('layer_type', 'TICKET');
      // vectorLayer.set('layer_name', 'TICKET LAYER');

      this.layers.map.addLayer(vectorLayer);
      this.layers.map.addLayer(distanceLayer);

      const extent = olExtent.createEmpty();
      olExtent.extend(extent, vectorLayer.getSource().getExtent());
      this.layers.map.getView().fit(extent, this.layers.map.getSize());
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}

#define-map {
  margin: -10px auto;
}

.legend {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: auto;
  border-radius: 5px;
  padding: 10px;
  background: white;
}

.legend-item {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.legend-color {
  margin-right: 5px;
}
</style>
